import React from 'react';

import './App.css';

import abstrakt from './img/abstract.jpg';
import bullied from './img/bullied.jpg';
import cover from './img/cover.jpg';

function App() {
  return (
    <div className="padding">
      <p>
        Maybe this will be an archive of work someday. 
      </p>
      <p>
        In the meantime, email me if you would like a copy of a grim wordless
        comic called "Jeffrey". Read it, then email me again to tell me what you think.
      </p>
      <p>
        <a href="mailto:mail@larrry.ink">mail@larrry.ink</a>
      </p>
      <div className="preview-grid">
        <img src={cover} alt="Black ink splatters, with the name Jeffrey hand-written in  blood red" />
        <img src={bullied} alt="Jeffrey is shoved by a pack of bullies when reaching down to pick up a baseball at recess" />
        <img src={abstrakt} alt="Four splatters of ink slashed across the page in a quick motion" />
      </div> 
    </div>
  );
}

export default App;
